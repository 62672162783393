/* eslint-disable react/prop-types */
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { setReferralCode, setReferralEntityType } from '@services/identity.service';
import WithoutOpportunityPage from './WithoutOpportunityPage';
import { getQueryParamsFromUrl } from '@helpers/miscelleanous';
import 'animate.css/animate.min.css';
import Loading from '@atoms/Loading';
import MediaCoverage from '@components/Homepage/MediaCoverage';
import routes from '@helpers/routes';
const Image = dynamic(() => import('@atoms/Image'));
const Investors = dynamic(() => import('@components/Homepage/Investors'));
const DiscoverBonds = dynamic(() => import('@components/Referral/DiscoverBonds.js/index.js'));
const Products = dynamic(() => import('@components/Homepage/Products'));
const InvestmentOptions = dynamic(() => import('@components/Homepage/InvestmentOptions'));
const WhyInvest = dynamic(() => import('@components/Homepage/WhyInvest'));
const RegulatedFramework = dynamic(() => import('@components/Homepage/RegulatedFramework'));
const SignupStickyView = dynamic(() => import('./SignupStickyView'));
const WithOpportunityPage = dynamic(
    () => import('./WithOpportunityPage'),
    { loading: () => <div className='min-h-[100vh] flex justify-center items-center'><Loading textVisible='false' /></div> }
);
const Footer = dynamic(() => import('@molecules/Footer'), {
    ssr: false,
});

const Referral = ({ showEmail, opportunityDetails, login, showMuhuratDays = false }) => {
    const router = useRouter();

    const [referral, setReferral] = useState(null);
    const [referralEntity, setReferralEntity] = useState(null);
    const [showSignUp, setShowSignUp] = useState('hidden');

    useEffect(() => {
        const referralCode = router.query?.referrer || router.query?.usoc || getQueryParamsFromUrl(window.location.search, 'usoc') || getQueryParamsFromUrl(window.location.search, 'referrer') || getQueryParamsFromUrl(window.location.search, 'v2_referrer');

        if (referralCode) {
            if (
                router.query?.usoc ||
                getQueryParamsFromUrl(window.location.search, 'usoc')
            ) {
                setReferralEntity('usoc');
                setReferralEntityType('usoc');
            } else {
                setReferralEntity('referrer');
                setReferralEntityType('referrer');
            }
            setReferralCode(referralCode);
            setReferral(referralCode);
        }
    }, []);

    return (
        <div className="relative signup-page-wrapper">
            <div className="relative z-50 overflow-hidden">
                {opportunityDetails ? (
                    <WithOpportunityPage
                        referralCode={referral}
                        referralEntity={referralEntity}
                        showEmail={showEmail}
                        opportunityDetails={opportunityDetails}
                        login={login}
                        setShowSignUp={setShowSignUp}
                    />
                ) : (
                    <WithoutOpportunityPage
                        referralCode={referral}
                        referralEntity={referralEntity}
                        showEmail={showEmail}
                        login={login}
                        setShowSignUp={setShowSignUp}
                        showMuhuratDays={showMuhuratDays}
                    />
                )}
                <div className="relative z-40 -mt-20 xl:mt-0">
                    <div className="xl:w-[95%] pb-[100px] px-6">
                        <DiscoverBonds horizontalLayoutForTablet={true} />
                    </div>
                    <div className="md:-mt-16 xl:mt-0">
                        <Products
                            onButtonClick={() => router.push(routes.loggedOutOpportunities)}
                        />
                    </div>

                    <InvestmentOptions />
                    <WhyInvest />
                    <RegulatedFramework />
                    <MediaCoverage shouldLinkWork={false} />
                    <Investors />
                </div>

                {!opportunityDetails && (
                    <Image
                        src="/images/background/jiraaf-light-gray-bg-sleeping-desktop.svg"
                        className="absolute z-0 rotate-[45deg] left-[0px] top-[300px] scale-[2.5] md:rotate-[30deg] md:left-[300px] md:top-[550px] md:scale-[3] xl:rotate-[25deg] xl:left-0 xl:top-[300px] xl:scale-[1.5] origin-center"
                    />
                )}
            </div>

            <SignupStickyView showSignUp={showSignUp} />
            <Footer isLightFooter={false} />
        </div>
    );
};

export default Referral;
