import LazyImage from "@atoms/LazyImage";
import Text from "@atoms/Text";
import { JiraafAdvantages } from "@helpers/referralPageData";

const JiraafBenefits = () => {
    return (
        <div className="flex flex-col md:flex-row gap-x-6 gap-y-10 items-center md:items-start">
            <div className="space-y-6 mt-12 md:mt-14 xl:mt-10 ">
                {JiraafAdvantages?.map((item, index) => (
                    <div
                        key={index}
                    >
                        <Text className="text-primary-500 h5-semibold xl:h4-semibold" content={item.title} />
                        <Text className="mt-0.5 text-gray-500 p4-regular xl:p3-regular" content={item.description} />
                    </div>
                ))}
            </div>
            <div className=''>
                <LazyImage src='/images/homepage/JiraafMascot.webp' alt="Jiraaf_Mascot" className="hidden md:block xl:hidden min-w-[183px] min-h-[296px] w-[183px] md:mt-14 xl:mt-0 relative" />
                <LazyImage src='/images/mascot-in-white.webp' alt="Jiraaf_Mascot" className="md:hidden w-[301px] h-[298px] relative" />
            </div>
        </div>
    );
};

export default JiraafBenefits;