import { LazyImage } from '@atoms/index';
import LoginModalForm from '@components/Auth/LoginModal/LoginModalForm';
import VerifyOtpModal from '@components/Auth/VerifyOtpModal';
import useIntersection from 'hooks/useIntersection';
import { useRef, useState, useEffect } from 'react';
import HeaderSection from './HeaderSection';
import JiraafBenefits from './JiraafAdvantages';
import texts from '@components/Referral/en.json';
import VerifyPhoneAndEmailModal from '@components/Auth/VerifyPhoneAndEmailModal';
import { useRouter } from 'next/router';
import { loginUser, onLoginSignupSuccess } from '@services/session.service';
import useAuthStore from '@store/useAuthStore';
import { OtpContext } from '@ui/helpers/enums/otp-context';
import MessageModal from '@molecules/MessageModal';
import Button from '@ui/atoms/design-system/Button';
import useWindowDimensions from '@hooks/useWindowDimensions';
import useUserLevelNudges from '@hooks/useUserLevelNudges';
import { cn } from '@ui/atoms/utils/utils';

const WithoutOpportunityPage = ({
    showEmail,
    referralCode,
    referralEntity,
    login,
    setShowSignUp,
    showMuhuratDays = false
}) => {

    const [showEmailInput, setShowEmailInput] = useState(showEmail);
    const [showVerifyOtpModal, setShowVerifyOtpModal] = useState(false);
    const [userInput, setUserInput] = useState({});
    const [loading, setLoading] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [isSessionCreationError, setIsSessionCreationError] = useState(false);
    const mobileRef = useRef(null);
    const emailRef = useRef(null);
    const [otpModes, setOtpModes] = useState([]);
    const [isModalInLoadingState, setIsModalInLoadingState] = useState(false);
    const router = useRouter();
    const { login: authStoreLogin } = useAuthStore();
    const { width } = useWindowDimensions();
    const { fetchNudges } = useUserLevelNudges({
        userId: null,
    });

    const editMobileNumber = () => {
        setShowVerifyOtpModal(false);
        mobileRef.current?.focus();
    };

    const editEmail = () => {
        setShowVerifyOtpModal(false);
        emailRef.current?.focus();
    };

    const onBack = () => {
        setIsSessionCreationError(false);
        setShowVerifyOtpModal(false);
        setResetForm(true);
    };

    const onLoginModalClose = () => {
        setShowVerifyOtpModal(false);
        setLoading(false);
    };
    const onSessionCreationError = () => setIsSessionCreationError(true);

    const onSessionCreationSuccess = async ({ user }) => {
        return onLoginSignupSuccess({
            user,
            isSignup: showEmailInput,
            authStoreLogin,
            router,
            userInput,
            referralEntity,
            fetchNudges,
        });
    }

    const onOtpVerificationSuccessForSignup = async ({ otpIdList }) => {
        const response = await loginUser(userInput.mobile, otpIdList, userInput);
        if (response.status) {
            await onSessionCreationSuccess({ user: response.entity });
        } else {
            setIsSessionCreationError(!response.status);
        }
    };

    const [showsignup, setshowsignUp] = useState(!login);
    const ref = useRef();
    const inViewport = useIntersection(ref, '-100px');

    useEffect(() => {
        if (!inViewport) {
            setShowSignUp('block');
        } else {
            setShowSignUp('hidden');
        }
    }, [inViewport]);

    const handleLoginToExistingAccount = () => {
        setShowEmailInput(false);
        setshowsignUp(false);
        setResetForm(true);
    };

    const handleCreateAccount = () => {
        setShowEmailInput(true);
        setshowsignUp(true);
        setResetForm(true);
    };

    return (
        <>
            <div className={`relative z-50 w-full`}>
                {/* logo bar for tablet and mobile view */}
                <div className="bg-basicWhite w-full xl:hidden flex justify-center fixed top-0 z-100 p-4">
                    <LazyImage src="/logo.svg" className="w-[86px] h-[24px] relative" />
                </div>
                <div className='mt-14 xl:mt-0 mx-4 md:mx-6 xl:mx-0 flex-col flex xl:flex-row gap-x-6 xl:items-start pb-4 md:pb-0 xl:pt-16 xl:px-6 items-center'>
                    {/* Side Bar With Info */}
                    <div className='xl:basis-3/5'>
                        <HeaderSection showMuhuratDays={showMuhuratDays} />
                        <div className='hidden xl:block'>
                            <JiraafBenefits />
                        </div>
                    </div>
                    {/* Sign Up Section */}
                    <div className='mt-6 w-full xl:mt-0 xl:basis-2/5 xl:space-y-16'>
                        <div ref={ref} className="gap-y-4 md:gap-y-6 flex flex-col bg-basicWhite p-4 md:p-6 rounded-2xl shadow-custom-sm"
                            id="signUpForm">
                            <div className="flex justify-between items-center">
                                <h1 className="text-basicBlack h6-semibold md:h4-semibold">
                                    {showEmailInput ? texts?.SignUp : texts?.Login}
                                </h1>
                                {showEmailInput && (
                                    <div className="text-gray-600 p5-regular md:p4-regular flex gap-x-1">
                                        {`${texts?.AccountExists} `}
                                        <Button
                                            buttonType='text'
                                            buttonSize={width < 768 ? 'extraSmall' : 'small'}
                                            onClick={handleLoginToExistingAccount}
                                            buttonText={texts?.Login}
                                            type='button'
                                        />
                                    </div>
                                )}
                                {!showEmailInput && (
                                    <div className="text-gray-600 p5-regular md:p4-regular flex gap-x-1">
                                        {`${texts?.CreateAccount} `}
                                        <Button
                                            buttonType='text'
                                            buttonSize={width < 768 ? 'extraSmall' : 'small'}
                                            onClick={handleCreateAccount}
                                            buttonText={texts?.SignUp}
                                            type='button'
                                        />
                                    </div>
                                )}
                            </div>

                            <LoginModalForm
                                isStatic={true}
                                showEmailInput={showEmailInput}
                                setShowEmailInput={setShowEmailInput}
                                fromOpportunitySignup={false}
                                userInput={userInput}
                                setUserInput={setUserInput}
                                setShowVerifyOtpModal={setShowVerifyOtpModal}
                                loading={loading}
                                setLoading={setLoading}
                                setShowSignUp={setshowsignUp}
                                showSignUp={showsignup}
                                resetForm={resetForm}
                                setResetForm={setResetForm}
                                emailRef={emailRef}
                                mobileRef={mobileRef}
                                handleCloseModal={onLoginModalClose}
                                setOtpModes={setOtpModes}
                                setModalLoadingState={setIsModalInLoadingState}
                            />

                            {showVerifyOtpModal && !isSessionCreationError
                                ? showEmailInput
                                    ? (
                                        <div className="mx-20 md:mx-10 w-full">
                                            <VerifyPhoneAndEmailModal
                                                show={showVerifyOtpModal}
                                                title="Verify Details"
                                                mobileContext={OtpContext.MOBILE_VERIFICATION}
                                                onClose={onLoginModalClose}
                                                mobileNumber={userInput.mobile}
                                                emailId={userInput.email}
                                                editMobileNumber={editMobileNumber}
                                                editEmailId={editEmail}
                                                countryCode={userInput.countryCode}
                                                onSuccess={onOtpVerificationSuccessForSignup}
                                            />
                                        </div>
                                    )
                                    : (
                                        <div className="mx-20 md:mx-10 w-full">
                                            <VerifyOtpModal
                                                referralCode={referralCode}
                                                referralEntity={referralEntity}
                                                show={showVerifyOtpModal}
                                                onClose={onLoginModalClose}
                                                editMobileNumber={editMobileNumber}
                                                userInput={userInput}
                                                showEmailInput={showEmailInput}
                                                onSessionCreationError={onSessionCreationError}
                                                onSessionCreationSuccess={onSessionCreationSuccess}
                                                otpModes={otpModes}
                                            />
                                        </div>
                                    ) : null}

                            {isSessionCreationError &&
                                <MessageModal
                                    isError={isSessionCreationError}
                                    show={isSessionCreationError}
                                    title={showEmailInput ? "Verify Details" : "Unable to login"}
                                    buttonText="Try again"
                                    message={showEmailInput ? "Unable to sign you up. Please try again." : "There was an error logging in. Please try again."}
                                    onClick={onBack}
                                    onClose={onLoginModalClose}
                                />
                            }
                        </div>
                        <LazyImage
                            src="/images/homepage/JiraafMascot.webp"
                            alt="Jiraaf_Mascot"
                            className={cn(
                                'hidden xl:block w-[392px] h-[634px] absolute right-6 xl:-right-4',
                                {
                                    'top-[560px]': !showEmailInput,
                                }
                            )}
                        />
                    </div>
                    <div className="self-start  xl:hidden">
                        <JiraafBenefits />
                    </div>
                </div>
            </div>
        </>
    );
};

export default WithoutOpportunityPage;
