import texts from '@components/Referral/en.json';

export const BannerData = [
    {
        header: texts?.Explore,
        description: texts?.ExploreDescription,
        image: '/images/homepage/explore.svg'
    },
    {
        header: texts?.Invest,
        description: texts?.InvestDescription,
        image: '/images/homepage/invest.svg'
    },
    {
        header: texts?.Maximise,
        description: texts?.MaximiseDescription,
        image: '/images/homepage/maximise.svg'
    }
];

export const JiraafAdvantages = [
    {
        title: texts?.BigReturns,
        description: texts?.BigReturnsDescription,
    },
    {
        title: texts?.PortfolioDiversification,
        description: texts?.PortfolioDiversificationDescription,
    },
    {
        title: texts?.PlannedPayouts,
        description: texts?.PlannedPayoutsDescription,
    },
];