import Text from "@atoms/Text";
import texts from './en.json';
import Image from "@atoms/Image";
import MuhuratDaysOfferText from "@components/Homepage/Banner/MuhuratDaysOfferText";

const HeaderSection = ({
    showMuhuratDays = false
}) => {
    return (
        <div>
            {/* Logo Section */}
            <div className="hidden xl:block">
                <Image className="w-[144px] h-[40px]" alt="jiraaf logo" src="/images/logo.svg" />
            </div>
            
            {showMuhuratDays ? <div className="max-w-[350px] xl:max-w-[365px] mt-6 xl:mt-12 mx-auto xl:mx-0"><MuhuratDaysOfferText /></div> : null}
            {/* Heading */}
            <div className={`text-center xl:text-start ${showMuhuratDays ? 'mt-6' : 'mt-8 xl:mt-12'}`}>
                <Text className="h5-semibold md:h3-semibold text-primary-500" content={texts?.OnlineBondPlatform} />
                <Text className="h5-semibold md:h3-semibold text-primary-500" content={texts?.FixedIncomeInvestments} />
                <Text className="mt-3 p4-medium md:p3-medium text-primary-500" content={texts?.regulatedBySebi} />
            </div>
        </div>
    );
};

export default HeaderSection;